import { RedStar } from '@components/modules/DropZoneModals/styled';
import { findCompany } from '@redux/myExports/actions';
import React from 'react';
import CustomAutocomplete from '@components/elements/CustomAutocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { MyExportsInterface, RootState } from '@redux/interfaces';

const CompanySelector = ({
  formik,
  tankId,
  disabled = false,
  required = true,
  agreementModal = false,
}: {
  formik: any;
  tankId: number | string;
  disabled?: boolean;
  required?: boolean;
  agreementModal?: boolean;
}) => {
  const { companies } = useSelector<RootState, MyExportsInterface>(
    (state) => state.myExports
  );

  const dispatch = useDispatch();

  return (
    <CustomAutocomplete
      id="companyName"
      fullWidth
      disabled={disabled}
      label={
        <>
          {required && <RedStar />}
          NAZWA OPERATORA
        </>
      }
      options={companies}
      loadOnOpen={() => {
        dispatch(findCompany(`${tankId}/?only_active=${agreementModal}&term=`));
      }}
      inptValue={
        agreementModal
          ? formik.values.companyName?.name
            ? formik.values.companyName?.name
            : ''
          : undefined
      }
      value={agreementModal ? undefined : formik.values.companyName}
      inputOnChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (agreementModal) {
          formik.setFieldValue('companyName.name', e.target.value);
        }
        if (e.target.value.length >= 3) {
          dispatch(
            findCompany(
              `${tankId}/?only_active=${agreementModal}&term=${e.target.value}`
            )
          );
        }
      }}
      callOnChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue('companyName', e);
      }}
      error={formik.touched.companyName && Boolean(formik.errors.companyName)}
      helperText={
        formik.touched.companyName?.name && formik.errors.companyName?.name
      }
      noOptionsText={
        !agreementModal
          ? 'Brak nazwy - zgłoś do nas firmę, której brakuje na liście'
          : ''
      }
    />
  );
};

export default CompanySelector;
