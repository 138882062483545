import {
  Box,
  FormControl,
  FormLabel,
  Link,
  Switch,
  Typography,
} from '@mui/material';
import theme from '@styles/theme';
import React from 'react';

type PropsT = {
  contractAccepted: boolean;
  setContractAccepted: (prevState: any) => void;
  getContract: () => void;
  noTopBorder?: boolean;
};

const SwitchConfirmAgreement = ({
  contractAccepted,
  setContractAccepted,
  getContract,
  noTopBorder,
}: PropsT) => (
  <Box
    sx={{
      borderBottom: '2px solid #B3C6C0',
      borderTop: noTopBorder ? 'unset' : '2px solid #B3C6C0',
      pb: 2,
    }}
  >
    <FormControl>
      <Box>
        <Switch
          id="contract"
          name="contract"
          color="primary"
          checked={contractAccepted}
          onChange={() => setContractAccepted((prevState: any) => !prevState)}
          data-cy="contract"
        />
        <FormLabel disabled>
          <Typography color="red" sx={{ display: 'inline-block' }}>
            *
          </Typography>{' '}
          Wyrażam zgodę na zawarcie umowy z wybranym operatorem na wywóz
          nieczystości płynnych.{' '}
          <Link
            target="_blank"
            data-cy="statute-url"
            sx={{
              color: theme.palette.primary.dark,
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
            onClick={() => getContract()}
          >
            Zobacz umowę.
          </Link>
        </FormLabel>
      </Box>
    </FormControl>
  </Box>
);

export default SwitchConfirmAgreement;
