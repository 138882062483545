import React, { useEffect, useRef, useState } from 'react';
import { Typography, Box, Skeleton } from '@mui/material';
import { BorderButton, CardTitle } from '@styles/styled';
// @ts-ignore - lodash.js' implicitly has an 'any' type.
import { orderBy } from 'lodash';

import theme from '@styles/theme';
import {
  TableWrapper,
  GridItem,
  ShortertItem,
  GridContainer,
  ScrollBox,
  DisabledOnMobile,
} from '@components/elements/ContractsComponent/styled';
import { SingleLine } from '@components/elements/ContractsComponent/SingleLine';
import { useDispatch, useSelector } from 'react-redux';
import {
  AgreementsInterface,
  RootState,
  SingleAgreementInterface,
  UserTanksInterface,
} from '@redux/interfaces';
import SortArrows from '@components/elements/SortArrows';
import AddNewContractModal from 'components/modules/DropZoneModals/AddNewContractModal';
import { getAgreementList } from '@redux/agreements/actions';
import { resetCompanies } from '@redux/myExports/actions';
import useWindowDimensions from '@hooks/useWindowDimension';
import AgreementDetails from '@components/modules/DropZoneModals/AgreementDetails';
import DeleteRecord from '@components/elements/DropsGrid/DeleteRecord';
import SemiTransparentBackground from '@components/layouts/SemiTransparentBackground';
import SignNewAgreementModal, {
  SignNewAgreementModalRef,
} from '@components/layouts/modals/SignNewAgreementModal';
import { styled } from '@mui/material/styles';

const ButtonWrapper = styled(Box)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 16px;
  flex-direction: row;

  @media (max-width: 660px) {
    flex-direction: column;
  }
`;

const ContractsComponent = () => {
  const [addNewContractVisible, setAddNewContractVisible] = useState(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [deleteAgreement, setDeleteAgreement] = useState<boolean>(false);
  const [selectedAgreement, setSelectedAgreement] =
    useState<SingleAgreementInterface | null>(null);
  const [agreementCollection, setAgreementCollection] = useState<
    Array<SingleAgreementInterface>
  >([]);

  const signNewAgreementModalRef = useRef<SignNewAgreementModalRef>(null);

  const { width } = useWindowDimensions();

  const { selectedTankId, tankDetailsLoading } = useSelector<
    RootState,
    UserTanksInterface
  >((state) => state.userCesspools);

  const { agreements, loading } = useSelector<RootState, AgreementsInterface>(
    (state) => state.agreements
  );

  const dispatch = useDispatch();

  const handleColumnHeaderClick = (
    sortKey: string,
    sortDirection: 'asc' | 'desc' = 'asc'
  ) => {
    const sortedAgreementCollection = orderBy(
      agreementCollection,
      [sortKey],
      [sortDirection]
    );

    setAgreementCollection(sortedAgreementCollection);
  };

  const changeModalVisibility = (v: SingleAgreementInterface) => {
    setSelectedAgreement(v);

    if (width <= 660) {
      setShowDetails(true);
    } else {
      if (v?.added_by_user) setAddNewContractVisible(true);
    }
  };

  useEffect(() => {
    dispatch(getAgreementList());
  }, []);

  useEffect(() => {
    if (agreements.length > 0) {
      const filtered = agreements.filter(
        (item) => item.client_property === selectedTankId
      );
      setAgreementCollection(filtered);
    }
  }, [agreements, selectedTankId]);

  return (
    <Box>
      <SignNewAgreementModal ref={signNewAgreementModalRef} />
      {addNewContractVisible && (
        <AddNewContractModal
          close={() => {
            setAddNewContractVisible(false);
            setSelectedAgreement(null);
          }}
          selectedAgreement={selectedAgreement}
        />
      )}
      {showDetails && (
        <AgreementDetails
          close={() => {
            setShowDetails(false);
            setSelectedAgreement(null);
          }}
          selectedAgreement={selectedAgreement}
          edit={() => {
            setShowDetails(false);
            setAddNewContractVisible(true);
          }}
          deleteAgreement={() => {
            setShowDetails(false);
            setDeleteAgreement(true);
          }}
        />
      )}
      {deleteAgreement && selectedAgreement && (
        <SemiTransparentBackground>
          <DeleteRecord
            closeModal={() => {
              setDeleteAgreement(false);
              setSelectedAgreement(null);
            }}
            item={selectedAgreement}
            loading={loading}
            label="Usuń umowę"
          />
        </SemiTransparentBackground>
      )}
      <CardTitle sx={{ mb: 3 }} id="agreements">
        Umowy z firmami asenizacyjnymi
      </CardTitle>
      <ScrollBox>
        <TableWrapper>
          <GridContainer>
            <ShortertItem>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                Data
              </Typography>
              <SortArrows
                sortKey="conclusion_date"
                setSort={handleColumnHeaderClick}
              />
            </ShortertItem>
            <GridItem>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                Nazwa firmy asenizacyjnej
              </Typography>
              <SortArrows
                sortKey="company_name"
                setSort={handleColumnHeaderClick}
              />
            </GridItem>
            <DisabledOnMobile>
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 'bold',
                }}
              >
                Status
              </Typography>
            </DisabledOnMobile>
            <DisabledOnMobile>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                Umowa
              </Typography>
            </DisabledOnMobile>
            <DisabledOnMobile>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                Usuń
              </Typography>
            </DisabledOnMobile>
          </GridContainer>
          {agreementCollection.length >= 1 &&
            agreementCollection.map((item) => (
              <SingleLine
                key={item.id}
                item={item}
                deleteAgreement={(v: SingleAgreementInterface) => {
                  setSelectedAgreement(v);
                  setDeleteAgreement(true);
                }}
                getDetails={(v: SingleAgreementInterface) => {
                  changeModalVisibility(v);
                }}
              />
            ))}
          {!tankDetailsLoading && agreementCollection.length === 0 && (
            <Box sx={{ mt: 2 }}>
              <Typography
                variant="h6"
                color={theme.palette.primary.contrastText}
              >
                Brak umów do wyświetlenia
              </Typography>
            </Box>
          )}
          {tankDetailsLoading && (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={56}
              sx={{ marginTop: '15px' }}
            />
          )}
        </TableWrapper>
      </ScrollBox>
      {!tankDetailsLoading && (
        <ButtonWrapper>
          <BorderButton
            disabled={selectedTankId === 0}
            data-cy="add_new_agreement"
            onClick={() => {
              setAddNewContractVisible(true);
              setSelectedAgreement(null);
              dispatch(resetCompanies());
            }}
          >
            Dodaj nową umowę
          </BorderButton>
          <p>lub</p>
          <BorderButton
            disabled={selectedTankId === 0}
            data-cy="sign_agreement"
            onClick={() => {
              if (signNewAgreementModalRef.current) {
                signNewAgreementModalRef.current.openModal();
              }
            }}
          >
            Podpisz umowę
          </BorderButton>
        </ButtonWrapper>
      )}
    </Box>
  );
};
export default ContractsComponent;
