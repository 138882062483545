import { CardDescription } from '@styles/styled';
import React from 'react';
import { Typography } from '@mui/material';
import DownloadButton from '@components/elements/DownloadButton';
import { getFormatedDate } from '@utils/formatDate';

type DownloadReadyProtocolT = {
  address: string;
  downloadProtocol: () => void;
  protocolSendDate: string;
};

const DownloadReadyProtocol = ({
  address,
  downloadProtocol,
  protocolSendDate,
}: DownloadReadyProtocolT) => (
  <>
    <CardDescription sx={{ fontWeight: 'bold' }}>
      PROTOKÓŁ CYFROWEJ KONTROLI MIESZKAŃCA
    </CardDescription>
    <Typography fontSize="14px">
      Protokół cyfrowej kontroli został przekazany do urzędu w dniu{' '}
      {getFormatedDate(protocolSendDate)}. Poniżej znajduje się kopia wysłanego
      dokumentu.
    </Typography>
    <Typography fontSize="20px" fontWeight="bold" mt={2}>
      PROTOKÓŁ KONTROLI
    </Typography>
    <Typography fontSize="20px" fontWeight="bold" mt={1}>
      {address}
    </Typography>
    <DownloadButton
      sx={{ marginTop: 2 }}
      downloadFn={() => downloadProtocol()}
    />
  </>
);

export default DownloadReadyProtocol;
