'use client';

import { types } from '@redux/agreements/types';

export const downloadAgreement = (id: string) => {
  return {
    type: types.DOWNLOAD_AGREEMENT_REQUEST,
    id,
  };
};

export const getAgreementList = () => {
  return {
    type: types.GET_AGREEMENT_LIST_REQUEST,
  };
};

export const addNewAgreement = (data: FormData) => {
  return {
    method: 'POST',
    type: types.ADD_NEW_AGREEMENT_REQUEST,
    formData: data,
  };
};

export const editAgreement = (id: string, data: FormData) => {
  return {
    method: 'PATCH',
    type: types.EDIT_AGREEMENT_REQUEST,
    id,
    formData: data,
  };
};

export const deleteAgreement = (id: string) => {
  return {
    method: 'DELETE',
    type: types.DELETE_AGREEMENT_REQUEST,
    id,
  };
};

export const signNewAgreement = (
  data: { code: string; company_id: string },
  id: string
) => {
  return {
    method: 'PUT',
    type: types.SIGN_NEW_AGREEMENT_REQUEST,
    data,
    id,
  };
};
