'use client';

import * as yup from 'yup';

export default yup.object({
  addAgreement: yup.boolean(),
  date: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .test(
      'required-when-add-agreement',
      'Data umowy jest wymagana',
      function (value) {
        const { addAgreement } = this.parent;
        if (addAgreement && !value) {
          return this.createError({ message: 'Data umowy jest wymagana' });
        } else if (!addAgreement && !value) {
          return this.createError({ message: 'Data odbioru jest wymagana' });
        }
        return true;
      }
    ),
  companyName: yup
    .object()
    .shape({
      name: yup.string().required('Nazwa operatora jest wymagana'),
    })
    .nullable()
    .required('Nazwa operatora jest wymagana'),
  file: yup
    .boolean()
    .required('Plik jest wymagany')
    .oneOf([true], 'Plik jest wymagany'),
});
