'use client';

import * as yup from 'yup';

export default yup.object({
  sewageAmount: yup
    .string()
    .nullable()
    .matches(/^\d+([.,]\d+)?$/, 'Ilość ścieków musi być liczbą dziesiętną')
    .max(9999, 'Nie możesz podać takiej ilości ścieków'),
});
