'use client';

import { types } from './types';
import {
  AddNewTankInterface,
  CesspoolFeaturesInterface,
  OssfFeaturesInterface,
  postAddressInterface,
} from '@redux/interfaces';
import { TankDataInterface } from '@forms/TankDataForm/interface';

export const setSelectedTankIdAction = (data: number) => {
  return {
    type: types.SET_SELECTED_TANK_ID,
    data,
  };
};

export const addNewTankAction = (newTankData: AddNewTankInterface) => {
  return {
    type: types.ADD_NEW_TANK,
    newTankData,
  };
};

export const setNewTankName = (data: string) => {
  return {
    type: types.SET_NEW_TANK_NAME,
    data,
  };
};

export const deleteTankAction = (id: string) => {
  return {
    type: types.DELETE_TANK_REQUEST,
    id,
  };
};

export const resetTankDelete = () => {
  return {
    type: types.DELETE_TANK_RESET,
  };
};

export const getClientProperty = () => {
  return {
    type: types.GET_CLIENT_PROPERTY_REQUEST,
  };
};

export const getTankDetails = (id: string) => {
  return {
    type: types.GET_TANK_DETAILS_REQUEST,
    id,
  };
};

export const updateTankLocalization = (
  data:
    | postAddressInterface
    | { city: string; postal_code: string; cesspool_name: string },
  id: string
) => {
  return {
    type: types.UPDATE_TANK_LOCALIZATION_REQUEST,
    data,
    id,
  };
};

export const updateTankData = (
  data: CesspoolFeaturesInterface | OssfFeaturesInterface,
  id: string
) => {
  return {
    type: types.UPDATE_TANK_DATA_REQUEST,
    data,
    id,
  };
};

export const addNewTank = (data: postAddressInterface) => {
  return {
    type: types.SET_NEW_TANK_LOCALIZATION_REQUEST,
    data,
  };
};

export const disableLoading = () => {
  return {
    type: types.NEW_ACCOUNT_LOADING_FALSE,
  };
};

export const removeNewTank = () => {
  return {
    type: types.REMOVE_NEW_TANK,
  };
};

export const setTankDataValues = (data: TankDataInterface) => {
  return {
    type: types.SET_TANK_DATA_VALUES,
    data,
  };
};

export const assignVirtualControlCode = (
  data: { authcode: string },
  id: string
) => {
  return {
    type: types.ASSIGN_VIRTUAL_CONTROL_CODE,
    data,
    id,
  };
};

// Wysyłanie podpisanego protokołu na ostatnim kroku
export const uploadSignedProtocol = (id: string, data: FormData) => {
  return {
    type: types.UPLOAD_SIGNED_PROTOCOL_REQUEST,
    id,
    formData: data,
  };
};

export const getProtocol = (id: string) => {
  return {
    type: types.GET_PROTOCOL_ID_REQUEST,
    id,
  };
};

export const resetProtocolId = () => ({
  type: types.RESET_PROTOCOL_ID,
});

export const getProtocolFile = (id: string) => {
  return {
    type: types.GET_PROTOCOL_FILE_REQUEST,
    id,
  };
};

// Pobieranie szczegółów protokołu
export const getProtocolDetails = (id: string) => {
  return {
    type: types.GET_PROTOCOL_DETAILS_REQUEST,
    id,
  };
};

// Wysyłanie protokołu z komentarzem
export const sendProtocolComment = (
  id: string,
  data: {
    additional_info: string;
  }
) => {
  return {
    type: types.SEND_COMMENT_PROTOCOL_REQUEST,
    id,
    data,
  };
};
