import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

import { GradientButton } from '@styles/styled';
import signContractValidator from '../../../validators/signContractValidator';

import SwitchConfirmAgreement from '@components/elements/SwitchConfirmAgreement';
import CompanySelector from '@components/modules/DropZoneModals/CompanySelector';
import CustomTextField from '@components/elements/CustomTextField';
import UniversalModalLayout from '@components/layouts/UniversalModalLayout';

import {
  AgreementsInterface,
  RootState,
  UserTanksInterface,
} from '@redux/interfaces';
import { signNewAgreement } from '@redux/agreements/actions';
import AgreementExample from '@components/elements/AgreementExample';
import { types } from '@redux/agreements/types';

const StyledForm = styled('form')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

export interface SignNewAgreementModalRef {
  openModal: () => void;
}

type formT = {
  companyName: {
    id: string | null;
    name: string;
  };
  code: string;
  addAgreement: boolean;
};

const initialValues: formT = {
  companyName: {
    id: null,
    name: '',
  },
  code: '',
  addAgreement: false,
};

const SignNewAgreementModal = forwardRef<SignNewAgreementModalRef>(
  (props, ref) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [contractAccepted, setContractAccepted] = useState(false);
    const [showAgreementExample, setShowAgreementExample] = useState(false);

    const { selectedTankId } = useSelector<RootState, UserTanksInterface>(
      (state) => state.userCesspools
    );

    const { loading, agreementAddedOrEdited } = useSelector<
      RootState,
      AgreementsInterface
    >((state) => state.agreements);

    const dispatch = useDispatch();

    const formik = useFormik<formT>({
      initialValues: initialValues,
      validationSchema: signContractValidator,
      onSubmit: ({ code, companyName }) => {
        if (companyName?.id) {
          dispatch(
            signNewAgreement(
              { code: code, company_id: companyName.id },
              `${selectedTankId}/sign/`
            )
          );
        }
      },
    });

    useImperativeHandle(ref, () => ({
      openModal() {
        setIsModalVisible(true);
      },
    }));

    useEffect(() => {
      if (isModalVisible) {
        document.body.style.overflowY = 'hidden';
      }

      return () => {
        document.body.style.removeProperty('overflow-y');
      };
    }, [isModalVisible]);

    const getContract = () => {
      setShowAgreementExample(true);
    };

    useEffect(() => {
      if (agreementAddedOrEdited) {
        setIsModalVisible(false);
        formik.resetForm();
        dispatch({ type: types.RESET_ADD_AGREEMENT });
      }
    }, [agreementAddedOrEdited, loading]);

    if (!isModalVisible) return null;
    return (
      <UniversalModalLayout
        title={
          showAgreementExample
            ? 'Podgląd dokumentu'
            : 'Zawarcie umowy z operatorem'
        }
        description={
          showAgreementExample
            ? ''
            : 'Możesz podpisać zdalnie umowę na wywóz nieczystości płynnych z Twojej posesji. Poproś firmę asenizacyjną o indywidualny kod generowany w aplikacji Asenizacja.online'
        }
        close={() => {
          setIsModalVisible(false);
          setShowAgreementExample(false);
          formik.resetForm();
        }}
        modalPosition="flex-start"
      >
        {showAgreementExample ? (
          <AgreementExample exit={() => setShowAgreementExample(false)} />
        ) : (
          <StyledForm onSubmit={formik.handleSubmit}>
            <CompanySelector
              formik={formik}
              tankId={selectedTankId}
              agreementModal
            />
            <CustomTextField
              id="code"
              label="KOD OPERATORA"
              value={formik.values.code}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.code)}
              helperText={
                typeof formik.errors.code === 'string' && formik.errors.code
              }
            />
            <SwitchConfirmAgreement
              contractAccepted={contractAccepted}
              setContractAccepted={setContractAccepted}
              getContract={getContract}
              noTopBorder
            />
            <GradientButton
              sx={{ mb: 1, mt: 2 }}
              type="submit"
              disabled={loading || !contractAccepted}
              loading={loading}
            >
              Podpisz umowę
            </GradientButton>
          </StyledForm>
        )}
      </UniversalModalLayout>
    );
  }
);

SignNewAgreementModal.displayName = 'SignNewAgreementModal';

export default SignNewAgreementModal;
