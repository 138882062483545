import React, { ReactNode } from 'react';
import { Box, SvgIcon, Typography } from '@mui/material';
import scrollArrow from '../../../../public/icons/scrollArrow.svg';

type PropsT = {
  exit: () => void;
};

const Text = ({
  children,
  center = false,
}: {
  children: ReactNode;
  center?: boolean;
}) => (
  <Typography variant="body2" textAlign={center ? 'center' : 'left'}>
    {children}
  </Typography>
);

const SectionTitle = ({ children }: { children: ReactNode }) => (
  <Typography fontSize={20} fontWeight="bold" align="center" margin={3}>
    {children}
  </Typography>
);

const ListElement = ({ children }: { children: ReactNode }) => (
  <li style={{ marginBottom: '10px' }}>
    <Text>{children}</Text>
  </li>
);

const BackButton = ({ exit }: PropsT) => (
  <Box mb={3}>
    <p onClick={exit} style={{ cursor: 'pointer' }}>
      <SvgIcon
        component={scrollArrow}
        viewBox="0 0 75.414 20.452"
        sx={{
          fontSize: '4rem',
          margin: '-25px 10px',
        }}
      />
      Wróć do formularza
    </p>
  </Box>
);

const AgreementExample = ({ exit }: PropsT) => {
  return (
    <div>
      <BackButton exit={exit} />
      <Typography variant="h6" align="center">
        UMOWA RAMOWA NA WYWÓZ NIECZYSTOŚCI CIEKŁYCH - WZÓR
      </Typography>
      <div>
        <SectionTitle>§1 [Definicje]</SectionTitle>
        <Text center={true}>
          Na potrzeby niniejszej umowy, Strony ustalają brzmienie definicji,
          którymi będą się posługiwać w jej dalszej części:
        </Text>
        <ol>
          <ListElement>
            <Text>
              Aplikacja – aplikacja mobilna szambo.online, której właścicielem
              są Ścieki Polskie, służąca do składania Zamówień na wykonanie
              Usługi, możliwa do pobrania poprzez aplikację Sklep Play dla
              systemu operacyjnego Android, a w przyszłości także dla innych
              systemów operacyjnych;
            </Text>
          </ListElement>
          <ListElement>
            <Text>
              Regulamin - dokument o nazwie „Regulamin świadczenia usług drogą
              elektroniczną dla Użytkowników aplikacji mobilnej szambo.online”,
              udostępniony każdemu Klientowi w celu zapoznania się z jego
              treścią; Regulamin dostępny jest na stronie internetowej
              <a href="https://szambo.online/" target="_blank">
                www.szambo.online
              </a>
              ;
            </Text>
          </ListElement>
          <ListElement>
            <Text>
              Ścieki Polskie - Ścieki Polskie sp. z o.o. z siedzibą w Poznaniu
              przy ul. Nowowiejskiego 39, 61-733 Poznań, wpisana do rejestru
              przedsiębiorców prowadzonego przez Sąd Rejonowy Poznań - Nowe
              Miasto i Wilda w Poznaniu, Wydział VIII Gospodarczy, pod numerem
              KRS: 0000832415, NIP: 7812007071, REGON: 385715700;
            </Text>
          </ListElement>
          <ListElement>
            Umowa – niniejsza umowa ramowa, zawierana pomiędzy Operatorem a
            Klientem w formie dokumentowej, poprzez Aplikację lub poprzez inny
            kanał komunikacji, w szczególności formularz www, e-mail lub SMS;
          </ListElement>
          <ListElement>
            Urządzenie – każde urządzenie elektroniczne umożliwiające
            korzystanie z Aplikacji;
          </ListElement>
          <ListElement>
            Usługa – usługa wywozu nieczystości ciekłych w rozumieniu przepisów
            ustawy CPG ze zbiorników bezodpływowych, zamawiana u Operatora przez
            Klienta, w szczególności za pomocą Aplikacji;
          </ListElement>
          <ListElement>
            Ustawa CPG - ustawa z dnia 13 września 1996 r. o utrzymaniu
            czystości i porządku w gminach (tekst jedn. Dz. U. z 2019r. poz.
            2010 ze zm.);
          </ListElement>
          <ListElement>
            Zamówienie – przyjęcie przez Klienta udostępnionej w szczególności
            poprzez Aplikację oferty Operatora na realizację Usługi, z
            obowiązkiem zapłaty ceny; wzór Zamówienia określa Załącznik nr 1 do
            Umowy.
          </ListElement>
        </ol>
      </div>
      <div>
        <SectionTitle>§2 [Przedmiot Umowy]</SectionTitle>
        <ol>
          <ListElement>
            Przedmiotem niniejszej Umowy jest określenie ogólnych zasad
            zamawiania przez Klienta Usług u Operatora, za pośrednictwem
            Aplikacji lub telefonicznie. Zawarcie Umowy nie rodzi po stronie
            Klienta żadnych zobowiązań, albowiem do ich powstania konieczne jest
            złożenie przez Klienta Zamówienia.
          </ListElement>
          <ListElement>
            Operator oświadcza, iż posiada zezwolenie na prowadzenie
            działalności gospodarczej polegającej na świadczeniu Usług, wydane
            na podstawie art. 7 ust. 1 pkt 2 ustawy CPG.
          </ListElement>
          <ListElement>
            Umowa zostaje zawarta przez osoby upoważnione do reprezentowania
            Stron. Zawarcie umowy ani jej wykonanie nie stanowi i nie będzie
            stanowić naruszenia żadnych umów obowiązujących w stosunkach między
            Stronami.
          </ListElement>
        </ol>
      </div>
      <div>
        <SectionTitle>§3 [Składanie i realizacja Zamówień]</SectionTitle>
        <ol>
          <ListElement>
            Do realizacji Zamówienia, Klient powinien podać co najmniej miejsce
            wywozu, datę wywozu oraz pojemność zbiornika bezodpływowego.
            Pojemność zbiornika bezodpływowego określa maksymalną ilość
            nieczystości ciekłych jaką Operator odbiera od Klienta w ramach
            realizacji Usługi. Operator nie jest zobowiązany do wywozu większej
            ilości nieczystości ciekłych, aniżeli ilość wskazana w Zamówieniu,
            jednakże w razie faktycznego wywiezienia przez Operatora większej
            ilości niż wskazana w Zamówieniu, Klient zobowiązany jest do
            uiszczenia ceny za pełną ilość wywiezionych nieczystości ciekłych.
          </ListElement>
          <ListElement>
            Przed złożeniem Zamówienia, Operator poprzez Aplikację informuje
            Klienta o cenie oraz wszystkich elementach składowych ceny, które
            mają wpływ na ostateczną kwotę do zapłaty. Na podstawie tych
            informacji oraz danych podanych przez Klienta, Operator poprzez
            Aplikację lub telefonicznie podaje szacowaną i maksymalną cenę
            Usługi, według ilości zadeklarowanej przez Klienta w Zamówieniu
          </ListElement>
          <ListElement>
            Złożenie przez Klienta Zamówienia następuje z chwilą wybrania przez
            Klienta w Aplikacji przycisku „Zamawiam z obowiązkiem zapłaty” lub
            złożenia Zamówienia telefonicznie. Klient będzie uprawniony do
            złożenia Zamówienia wyłącznie w przypadku spełnienia warunków
            określonych w Regulaminie. Operator może odmówić realizacji Usługi w
            razie niespełnienia przez Klienta warunków określonych w
            Regulaminie.
          </ListElement>
          <ListElement>
            Klient poprzez Aplikację lub telefonicznie może anulować Zamówienie
            najpóźniej na dzień poprzedzający datę realizacji Usługi lub
            faktyczne rozpoczęcie realizacji Usługi. W takim przypadku Operator
            nie będzie pobierał od Klienta żadnej opłaty
          </ListElement>
          <ListElement>
            Jeżeli Klient zrezygnuje z Zamówienia w dniu realizacji Usługi,
            wskaże w Zamówieniu nieprawidłowe miejsce wywozu nieczystości lub
            nie udostępni punktu poboru nieczystości ciekłych ze zbiornika
            bezodpływowego, wówczas Operator może domagać się zapłaty ceny w
            maksymalnej kwocie wskazane w Zamówieniu.
          </ListElement>
          <ListElement>
            W ramach Usługi realizowanej na podstawie Zamówienia, Operator
            zobowiązuje się do jednorazowego odbioru zadeklarowanej przez
            Klienta ilości nieczystości ciekłych z miejsca wywozu,
            przetransportowania ich i oddania do stacji zlewnej. Dopuszcza się
            możliwość realizacji częściowego lub pełnego odbioru nieczystości
            ciekłych w okresie 1-2 Dni roboczych poprzedzających termin wywozu,
            o czym Operator powinien uprzedzić Klienta. Klient powyższe
            akceptuje i zobowiązuje się umożliwić Operatorowi wykonanie wywozu
            częściowego lub pełnego przed terminem określonym w Zamówieniu,
            chyba że Operator nie uprzedzi Klienta o wcześniejszym wywozie
          </ListElement>
          <ListElement>
            Klient zobowiązany jest do zachowania składu chemicznego
            nieczystości płynnych określonego w przez stację zlewną, do której
            Operator odprowadza nieczystości ciekłe. Aktualne parametry dla
            nieczystości ciekłych przedstawia poniższa tabela: [TABELA
            PARAMETRÓW] W przypadku zmiany któregokolwiek z parametrów, Operator
            w formie dokumentowej poinformuje Klienta o nowych dopuszczalnych
            wartościach nieczystości ciekłych będących przedmiotem Usługi.
          </ListElement>
          <ListElement>
            W ramach Usługi realizowanej na podstawie Zamówienia, Operator nie
            weryfikuje składu chemicznego nieczystości ciekłych odbieranych od
            Klienta. Weryfikacja składu nieczystości ciekłych następuje
            bezpośrednio przed oddaniem do stacji zlewnej. W przypadku
            zakwestionowania przez stację zlewną składu chemicznego nieczystości
            ciekłych odebranych od Klienta i odmowy odbioru lub nałożenia na
            Operatora kary bądź dodatkowej opłaty, Operator może obciążyć
            Klienta udokumentowanymi kosztami utylizacji nieczystości ciekłych
            oraz karami bądź opłatami nałożonymi przez stację zlewną
          </ListElement>
        </ol>
      </div>
      <div>
        <SectionTitle>§4 [Przetwarzanie danych osobowych</SectionTitle>
        <ol>
          <ListElement>
            Klient wyraża zgodę na przetwarzanie danych osobowych podanych
            podczas korzystania z Aplikacji, podczas rozmowy telefonicznej na
            potrzeby realizacji Zamówień przez Operatora lub poprzez inny
            dostępny kanał komunikacji, w szczególności formularz www, e-mail
            lub SMS, w zakresie szczegółowo opisanym w polityce prywatności
            dostępnej w Aplikacji oraz na stronie{' '}
            <a href="https://szambo.online/" target="_blank">
              www.szambo.online
            </a>
            . Wyrażenie zgody przez Klienta jest dobrowolne, jednakże cofnięcie
            zgody na przetwarzanie danych osobowych przez Klienta spowoduje, iż
            nie będzie on mógł korzystać z Aplikacji ani składać Zamówień, co
            będzie równoznaczne z wypowiedzeniem Umowy przez Klienta.
          </ListElement>
        </ol>
      </div>
      <div>
        <SectionTitle>§5 [Rozliczenia]</SectionTitle>
        <ol>
          <ListElement>
            Klient będzie realizował płatności za Usługę poprzez Aplikację lub w
            formie przelewu tradycyjnego, jeżeli taka możliwość zostanie
            udostępniona przez Ścieki Polskie. Formy płatności i termin zapłaty
            ceny określa Regulamin.
          </ListElement>
          <ListElement>
            Płatności od Klienta będą przyjmowane przez Ścieki Polskie na
            zasadach określonych w Regulaminie, przy czym maksymalna cena, którą
            Klient jest zobowiązany zapłacić za wykonanie Usługi będzie
            każdorazowo określona w Zamówieniu, z zastrzeżeniem przypadku
            opisanego w §3 ust. 1 zdanie trzecie. Uiszczenie przez Klienta ceny
            na rzecz Ścieków Polskich jest równoznaczne ze spełnieniem przez
            Klienta świadczenia na rzecz Operatora. Rozliczenia pomiędzy
            Ściekami Polskimi a Operatorem określa odrębna umowa.
          </ListElement>
          <ListElement>
            Cena maksymalna wskazana w Zamówieniu jest maksymalną ceną brutto i
            zawiera wszelkie składowe ceny w tym podatek VAT, w przypadku gdy
            Operator zobowiązany jest do ich uwzględnienia w cenie Usługi. W
            przypadku, o którym mowa w §3 ust. 1 zdanie trzecie cena maksymalna
            może być odpowiednio podwyższona.
          </ListElement>
          <ListElement>
            Po wykonaniu Usługi, Klient otrzyma od Ścieków Polskich fakturę lub
            inny dokument wystawiony w imieniu Operatora, określający
            rzeczywistą ilość odebranych nieczystości płynnych. W przypadku
            płatności kartą poprzez Aplikację, faktura może stanowić także
            dokument potwierdzający uiszczenie przez Klienta ceny za Usługę.
          </ListElement>
          <ListElement>
            Działając na podstawie art. 106n ust. 1 ustawy z dnia 11 marca 2004
            r. o podatku od towarów i usług, Strony zgodnie ustalają, że
            wszelkie faktury, faktury korygujące oraz ich duplikaty wystawiane
            przez Ścieki Polskie będą generowane w formacie *.pdf i udostępniane
            Klientowi poprzez Aplikację lub przesyłane pocztą elektroniczną na
            adres e-mail podany przez Klienta w Aplikacji lub telefonicznie, na
            co Klient niniejszym wyraża zgodę.
          </ListElement>
          <ListElement>
            W razie opóźnienia w zapłacie ceny, Operator naliczy Klientowi
            odsetki ustawowe za opóźnienie.
          </ListElement>
        </ol>
      </div>
      <div>
        <SectionTitle>§6 [Reklamacje]</SectionTitle>
        <ol>
          <ListElement>
            Operator jest zobowiązany do wykonania Usługi zgodnie z Zamówieniem
            oraz warunkami Umowy, jak i obowiązującymi przepisami prawa. W razie
            stwierdzenia przez Klienta nieprawidłowości w realizacji Usługi,
            może on złożyć reklamację poprzez stosowny formularz zamieszczony na
            stronie
            <a href="https://szambo.online/" target="_blank">
              www.szambo.online
            </a>
            , pocztą elektroniczną na adres e-mail [email protected] lub
            bezpośrednio do Operatora w formie dokumentowej.
          </ListElement>
          <ListElement>
            Klient wyraża zgodę na pośredniczenie przez Ścieki Polskie w
            rozpatrywaniu reklamacji i składanie przez Ścieki Polskie propozycji
            rozpatrzenia reklamacj
          </ListElement>
          <ListElement>
            W przypadku wyrażenia przez Klienta zgody na zaproponowany przez
            Ścieki Polskie sposób rozpatrzenia reklamacji, uznaje się, że
            reklamacja została rozpatrzona pozytywnie, a Klient nie będzie
            zgłaszał z tego tytułu roszczeń wobec Operatora.
          </ListElement>
          <ListElement>
            Jeżeli Klient nie wyrazi zgody na zaproponowany przez Ścieki Polskie
            sposób rozpatrzenia reklamacji lub Ścieki Polskie nie będą brały
            udziału w rozpatrzeniu reklamacji, Operator zobowiązuje się do
            rozpatrzenia reklamacji w terminie 21 dni od daty jej otrzymania.
          </ListElement>
          <ListElement>
            Wszelkie roszczenia z tytułu niewykonania lub nienależytego
            wykonania Usługi, które nie zostały uwzględnione w toku postępowania
            reklamacyjnego Klient będzie zgłaszał wyłącznie do Operatora.
          </ListElement>
        </ol>
      </div>
      <div>
        <SectionTitle>§7 [Klauzula salwatoryjna]</SectionTitle>
        <Text center={true}>
          W przypadku, gdy poszczególne postanowienia Umowy okażą się
          bezskuteczne lub niewykonalne, pozostałe postanowienia Umowy pozostają
          w mocy. Strony zobowiązują się w wyżej opisanym wypadku zastąpić
          postanowienia bezskuteczne lub niewykonalne innymi, w taki sposób aby
          jak najpełniej wypełniały one cel gospodarczy postanowień zastąpionych
        </Text>
      </div>
      <div>
        <SectionTitle>§8 [Obowiązywanie Umowy]</SectionTitle>
        <ol>
          <ListElement>Umowa zostaje zawarta na czas nieokreślony.</ListElement>
          <ListElement>
            Umowa zawierana jest z wykorzystaniem środka porozumiewania się na
            odległość, w związku z czym Klient może odstąpić od Umowy w terminie
            14 dni bez żadnych kosztów oraz bez podania przyczyn, wykorzystując
            w tym celu formularz odstąpienia, którego wzór wraz z informacją
            stanowi Załącznik nr 2 do Umowy.
          </ListElement>
          <ListElement>
            Uprawnienie do odstąpienia od Umowy wygasa z chwilą wykonania
            pierwszej Usługi przez Operatora zgodnie z Zamówieniem Klienta. Nie
            pozbawia to prawa Klienta do wypowiedzenia Umowy, zgodnie z
            postanowieniami ust. 4 poniżej.
          </ListElement>
          <ListElement>
            Każdej ze Stron przysługuje prawo do wypowiedzenia Umowy z
            zachowaniem 1 – miesięcznego okresu wypowiedzenia, ze skutkiem na
            koniec miesiąca kalendarzowego.
          </ListElement>
          <ListElement>
            Umowa ulega automatycznemu rozwiązaniu z chwilą usunięcia przez
            Klienta konta użytkownika na portalu www.szambo.online, a także z
            chwilą cofnięcia przez Klienta zgody na przetwarzanie jego danych
            osobowych, zgodnie z §4 Umowy.
          </ListElement>
          <ListElement>
            Z chwilą odstąpienia od Umowy, jej rozwiązania, wygaśnięcia lub
            upływie okresu wypowiedzenia, anulowaniu ulegają wszystkie
            Zamówienia, z zastrzeżeniem §3 ust. 5 Umowy.
          </ListElement>
        </ol>
      </div>
      <div>
        <SectionTitle>§9 [Postanowienia końcowe]</SectionTitle>
        <ol>
          <ListElement>
            W sprawach związanych z realizacją Umowy lub Zamówień Strony będą
            kontaktowały się telefonicznie, pod numerami telefonu wskazanymi w
            Zamówieniu lub mailowo na adres podany przez Klienta. Nadto Klient
            może komunikować się z Operatorem poprzez formularz kontaktowy
            zamieszczony na portalu{' '}
            <a href="https://szambo.online/" target="_blank">
              www.szambo.online
            </a>
          </ListElement>
          <ListElement>
            Zmiana postanowień Umowy dla swej ważności wymaga zachowania formy
            dokumentowej.
          </ListElement>
          <ListElement>
            W sprawach nieuregulowanych Umową, znajdują zastosowanie przepisy
            Kodeksu cywilnego, Ustawy CPG i innych ustaw.
          </ListElement>
          <ListElement>
            Wszystkie załączniki do niniejszej umowy stanowią jej integralną
            część. Załącznikami do niniejszej umowy jest:
            <ol>
              <ListElement>Załącznik nr 1 – wzór Zamówienia,</ListElement>
              <ListElement>
                Załącznik nr 2 – formularz odstąpienia od Umowy wraz z
                informacją.
              </ListElement>
            </ol>
          </ListElement>
          <ListElement>
            Umowa została sporządzona w formie dokumentowej, przesłana mailowo,
            poprzez formularz www, dedykowany link do projektu umowy przesłany
            poprzez SMS lub poprzez aplikację mobilną szambo.online i nie wymaga
            podpisów Stron. Klient potwierdza, że zapoznał się z treścią Umowy i
            akceptuje jej warunki akceptując odpowiednie pola formularza,
            przesyłając akceptację mailowo lub wysyłając SMS na wskazany numer o
            treści TAK (po uprzednim przesłaniu przez Operatora SMS-a z linkiem
            odsyłającym do treści projektu Umowy). W celu potwierdzenia zawarcia
            Umowy i Zamówienia, Ścieki Polskie w imieniu Operatora przekazują
            Klientowi Umowę oraz Zamówienia niezwłocznie po ich zawarciu w
            formie pliku elektronicznego *.pdf, który zostanie przesłany na
            adres e-mail Klienta wskazany podczas rejestracji lub SMS-em
            zawierającym link odsyłający do treści zawartej Umowy (po uprzednim
            przesłaniu przez Klienta SMS-a o treści TAK).
          </ListElement>
        </ol>
      </div>
    </div>
  );
};

export default AgreementExample;
