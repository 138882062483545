import React, { useEffect, useState } from 'react';
import { Box, FormLabel, Switch, TextField, Typography } from '@mui/material';
import theme from '@styles/theme';
import useWindowDimensions from '@hooks/useWindowDimension';

const MessageForDriver = ({ formik }: any) => {
  const [visible, setVisible] = useState(false);

  const { width } = useWindowDimensions();

  useEffect(() => {
    setVisible(width > 660);
  }, [width]);

  return (
    <Box>
      {width <= 660 && (
        <Box
          sx={{
            display: 'flex',
            borderBottom: '2px solid #B3C6C0',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Switch
            id="message"
            name="message"
            color="primary"
            checked={visible}
            onChange={() => setVisible((prevState: any) => !prevState)}
            data-cy="message"
          />
          <FormLabel>Dodatkowe informacje dla kierowcy</FormLabel>
        </Box>
      )}
      {visible && (
        <Box
          sx={{
            my: 2,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}
            color={theme.palette.secondary.main}
          >
            DODATKOWA INFORMACJE DLA KIEROWCY
          </Typography>
          <TextField
            multiline
            placeholder="Wprowadź treść informacji dla kierowcy..."
            minRows={3}
            fullWidth
            value={formik.values.message}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
            inputProps={{ maxlength: 600, 'data-cy': 'message' }}
            name="message"
            id="message"
            InputProps={{
              endAdornment: (
                <Typography color="gray">
                  {formik.values.message.length}/600
                </Typography>
              ),
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default MessageForDriver;
