'use client';

import * as yup from 'yup';

export default yup.object({
  companyName: yup
    .object()
    .shape({
      name: yup.string().required('Nazwa operatora jest wymagana'),
    })
    .nullable()
    .required('Nazwa operatora jest wymagana'),
  code: yup
    .string()
    .required('Kod jest wymagany')
    .matches(/^[0-9]+$/, 'Kod musi składać się z samych cyfr'),
});
