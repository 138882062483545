import { useFormik } from 'formik';
import {
  addNewExport,
  confirmIsAdded,
  resetCompanies,
  updateExport,
} from '@redux/myExports/actions';
import { useDispatch, useSelector } from 'react-redux';
import addDropValidator from '@components/modules/DropZoneModals/AddNewDropRecord/addDropValidator';
import contractValidator from '@components/modules/DropZoneModals/AddNewContractModal/contractValidator';
import {
  MyExportsInterface,
  RootState,
  SingleCLientPropertyInterface,
  UserTanksInterface,
} from '@redux/interfaces';
import React, { useEffect, useState } from 'react';

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SvgIcon,
  Typography,
} from '@mui/material';
import {
  ButtonWrapper,
  RedStar,
  Row,
} from '@components/modules/DropZoneModals/styled';
import DateSelector from '@components/modules/DropZoneModals/DateSelector';
import CustomTextField from '@components/elements/CustomTextField';
import CompanySelector from '@components/modules/DropZoneModals/CompanySelector';
import { DropzoneComponent } from '@components/modules/DropZoneModals/DropzoneComponent';
import theme from '@styles/theme';
import success from '../../../public/icons/successfull.svg';
import { BorderButton, GradientButton } from '@styles/styled';
import { styled } from '@mui/material/styles';
import { DropRecordModalsT } from '@customTypes/types';

const SuccessWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0 30px;
  gap: 30px;
`;

const IsAddedInfo = styled(Typography)`
  @media (max-width: 660px) {
    text-align: center;
  }
`;

const CustomFormControl = styled(FormControl)`
  max-width: 20vw;

  @media (max-width: 660px) {
    max-width: 100%;
  }
`;

const Bold = styled('span')`
  font-weight: 800;
  color: rgba(0, 0, 0, 0.54);
`;

const MailLink = styled('a')`
  color: ${theme.palette.primary.dark};
`;

const initialValues = {
  date: '',
  sewageAmount: '',
  cost: '',
  companyName: { id: '', name: '' },
  file: true,
};

type PropsT = DropRecordModalsT & {
  isReadOnly?: boolean;
};

const AddNewDropRecordForm = ({
  itemToEdit,
  closeModal,
  isReadOnly = false,
}: PropsT) => {
  const [isDropAdded, setIsDropAdded] = useState(false);
  const [selectedTank, setSelectedTank] = useState<string>('');
  const [isTankDeleted, setIsTankDeleted] = useState<boolean>(false);
  const [compressedFile, setCompressedFile] = useState<File | null>(null);
  const [properties, setProperties] = useState<
    Array<SingleCLientPropertyInterface>
  >([]);
  const [imageCompressionInProgress, setImageCompressionInProgress] =
    useState<boolean>(false);

  const dispatch = useDispatch();

  const validator = addDropValidator.concat(contractValidator);

  const { clientProperty } = useSelector<RootState, UserTanksInterface>(
    (state) => state.userCesspools
  );

  const { loading, isAdded, showNoCompanyInfo } = useSelector<
    RootState,
    MyExportsInterface
  >((state) => state.myExports);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validator,
    onSubmit: ({ date, sewageAmount, cost, companyName }) => {
      const { dirty } = formik;

      if (dirty || compressedFile) {
        const dateObject = new Date(date);
        const fixedDate =
          dateObject.getFullYear() +
          '-' +
          (dateObject.getMonth() + 1) +
          '-' +
          dateObject.getDate() +
          'T00:00:00+01:00';

        const formData = new FormData();
        if (compressedFile) {
          const blobFile = new File([compressedFile], compressedFile.name);
          formData.append('upload', blobFile);
        }
        formData.append('client_property', selectedTank);
        formData.append('date', fixedDate);
        formData.append('price', cost ? cost : '');
        formData.append('sanitation_company_name', companyName.name);
        if (companyName.id)
          formData.append('sanitation_company', companyName.id);
        formData.append('amount', sewageAmount);

        if (!itemToEdit) {
          dispatch(addNewExport(formData));
        } else {
          dispatch(updateExport(`${itemToEdit.id}/`, formData));
        }
      }
    },
  });

  const isSavedTankDelete = () => {
    if (itemToEdit) {
      const { client_property_id, client_property_name } = itemToEdit;

      const isTankFound = clientProperty.findIndex(
        (item) => item.id === client_property_id
      );

      if (isTankFound === -1) {
        setIsTankDeleted(true);
        setProperties([
          {
            id: client_property_id,
            cesspool_name: client_property_name,
            get_address: '',
            has_order: false,
            has_debt: false,
            has_order_to_accept: false,
          },
        ]);
      } else {
        setIsTankDeleted(false);
        setProperties(clientProperty);
      }
    }
  };

  useEffect(() => {
    if (itemToEdit) {
      const {
        sanitation_company_name,
        date,
        amount,
        price,
        sanitation_company_id,
      } = itemToEdit;

      const companyName =
        sanitation_company_name !== 'Unnamed' ? sanitation_company_name : '';

      const companyId = sanitation_company_id ? sanitation_company_id : '';

      const newState = {
        date: date,
        sewageAmount: amount ? String(amount) : '',
        cost: price ? String(price) : '',
        companyName: { id: companyId, name: companyName },
        file: true,
      };

      isSavedTankDelete();

      formik.resetForm({ values: newState });
    } else {
      setProperties(clientProperty);
    }
  }, []);

  useEffect(() => {
    if (itemToEdit) {
      setSelectedTank(String(itemToEdit.client_property_id));
    }
  }, [properties]);

  useEffect(() => {
    if (isAdded) handleAdd();
  }, [isAdded]);

  useEffect(() => {
    if (clientProperty.length > 0 && selectedTank === '') {
      setSelectedTank(`${clientProperty[0].id}`);
    }
  }, [clientProperty]);

  const handleAdd = () => {
    setIsDropAdded(true);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {!isDropAdded && (
        <Box>
          <Row>
            <DateSelector
              formik={formik}
              label="DATA WYWOZU"
              disabled={loading || isReadOnly}
              required={!isReadOnly}
              fieldName="date"
            />
            <CustomTextField
              id="sewageAmount"
              label="ILOŚĆ ŚCIEKÓW"
              value={formik.values.sewageAmount}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                formik.setFieldValue(
                  'sewageAmount',
                  e.target.value.replace(/,/g, '.')
                );
              }}
              error={
                formik.touched.sewageAmount &&
                Boolean(formik.errors.sewageAmount)
              }
              helperText={
                formik.touched.sewageAmount && formik.errors.sewageAmount
              }
              disabled={loading || isReadOnly}
              inputProps={{
                endAdornment: (
                  <Bold>
                    <p>[m³]</p>
                  </Bold>
                ),
              }}
            />
            <CustomTextField
              id="cost"
              label="KOSZT USŁUGI"
              value={formik.values.cost}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                formik.handleChange(e);
              }}
              error={formik.touched.cost && Boolean(formik.errors.cost)}
              helperText={formik.touched.cost && formik.errors.cost}
              disabled={loading || isReadOnly}
              inputProps={{
                endAdornment: (
                  <Bold>
                    <p>[zł]</p>
                  </Bold>
                ),
              }}
            />
          </Row>

          <Row>
            <CompanySelector
              formik={formik}
              tankId={selectedTank}
              disabled={loading || isReadOnly}
              required={!isReadOnly}
            />

            {(isTankDeleted || clientProperty?.length > 1) && (
              <CustomFormControl margin="normal" fullWidth>
                <InputLabel
                  id="clientTypeSelect-label"
                  sx={{
                    color: 'gray',
                    fontWeight: '700',
                  }}
                >
                  ZBIORNIK
                </InputLabel>
                <Select
                  fullWidth
                  labelId="clientTypeSelect-label"
                  id="clientTypeSelect"
                  value={selectedTank}
                  label=" ZBIORNIK "
                  variant="outlined"
                  disabled={loading || isTankDeleted || isReadOnly}
                  onChange={(e) => {
                    setSelectedTank(e.target.value as string);
                    dispatch(resetCompanies());
                    formik.setFieldValue('companyName', {
                      id: '',
                      name: '',
                    });
                  }}
                >
                  {properties &&
                    properties.map((item) => (
                      <MenuItem key={item.id} value={`${item.id}`}>
                        {item.cesspool_name}
                      </MenuItem>
                    ))}
                </Select>
              </CustomFormControl>
            )}
          </Row>

          {!isReadOnly && (
            <Box>
              <DropzoneComponent
                label="ZDJĘCIE KWITU LUB POTWIERDZENIA*"
                compressedFile={compressedFile}
                setCompressedFile={(f: any) => setCompressedFile(f)}
                imageCompressionInProgress={imageCompressionInProgress}
                setImageCompressionInProgress={setImageCompressionInProgress}
              />

              <Typography
                sx={{ pl: '10px', mt: 3, mb: 3, fontSize: '14px' }}
                color={theme.palette.secondary.main}
              >
                <RedStar /> Pola obowiązkowe
              </Typography>
            </Box>
          )}
        </Box>
      )}
      {showNoCompanyInfo && !isReadOnly && (
        <Typography p={1} mb={3}>
          Jeżeli na liście operatorów brakuje firmy asenizacyjnej, która
          świadczy usługi wywozu w twojej okolicy, napisz do nas na{' '}
          <MailLink href="mailto:pomoc@sciekipolskie.org">
            pomoc@sciekipolskie.org
          </MailLink>{' '}
          podając jej nazwę/NIP i wskaż gminę, w której znajduje się twój
          zbiornik.
        </Typography>
      )}
      {isDropAdded && (
        <SuccessWrapper>
          <SvgIcon
            component={success}
            viewBox="0 0 16.221 16.002"
            sx={{ fontSize: '96px' }}
          />
          <IsAddedInfo variant="h5" sx={{ fontWeight: 'bold' }}>
            {itemToEdit
              ? 'Twój wywóz został pomyślnie edytowany'
              : 'Twój wywóz został dodany do listy.'}
          </IsAddedInfo>
        </SuccessWrapper>
      )}
      {!isReadOnly && (
        <ButtonWrapper>
          {!isDropAdded ? (
            <GradientButton
              sx={{ m: 0 }}
              type="submit"
              loading={loading || imageCompressionInProgress}
              disabled={loading || imageCompressionInProgress}
            >
              Zapisz wywóz
            </GradientButton>
          ) : (
            <BorderButton
              onClick={() => {
                closeModal();
                dispatch(confirmIsAdded());
              }}
            >
              Historia wywozów
            </BorderButton>
          )}
        </ButtonWrapper>
      )}
    </form>
  );
};

export default AddNewDropRecordForm;
